import React from "react"
import { Button, Card, Col, Container, Jumbotron, Row } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <Row>
        <Col>
          <Jumbotron className={'bg-dark'}>
            <h1>Hi people</h1>
            <p>Welcome to Lily's page.</p>
            <p>This is going to be great!</p>
            {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
              <img src={'https://derpicdn.net/img/download/2013/1/12/210603.png'} alt='hero unicorn' />
            </div> */}
          </Jumbotron>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className={'bg-dark'} >
            <Card.Body>
              <Card.Title>Lessons</Card.Title>
              <Card.Text>
                Get started learning some stuff.
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button href="/resources/" variant='primary'>Start Learning</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className={'bg-dark'} >
            <Card.Body>
              <Card.Title>Rewards</Card.Title>
              <Card.Text>
                Get started learning some stuff.
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button href="/rewards/" variant='primary'>View Rewards</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className={'bg-dark'} >
            <Card.Body>
              <Card.Title>Rewards</Card.Title>
              <Card.Text>
                Get started learning some stuff.
              </Card.Text>
              {/* <Button variant="primary">Go somewhere</Button> */}
              <Button href="/rewards/" variant='primary'>View Rewards</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
